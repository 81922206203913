<template>
  <v-form ref="form">
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="name">Name</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-text-field
          id="name"
          v-model="name"
          outlined
          dense
          hide-details="auto"
          placeholder="Enter name"
          :disabled="loading"
          :rules="[required]"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="channels">Channels</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-textarea
          id="channels"
          v-model="channels"
          hide-details="auto"
          placeholder="Enter channels"
          :disabled="loading"
          :rules="[isNotEmptyString]"
        ></v-textarea>
      </v-col>
    </v-row>

    <v-col
      offset-md="3"
      cols="12"
    >
      <v-btn
        color="primary"
        :loading="loading"
        @click="submit"
      >
        Create
      </v-btn>
      <v-btn
        type="reset"
        class="mx-2"
        :disabled="loading"
        outlined
        @click="reset"
      >
        Reset
      </v-btn>
    </v-col>
  </v-form>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiAlertCircleOutline, mdiCalendar, mdiCheck } from '@mdi/js'

import { required, isNotEmptyString } from '@core/utils/validation'

export default {
  props: {
    loading: Boolean,
  },
  setup(props, { emit }) {
    const form = ref(null)
    const validate = () => form.value.validate()

    const reset = () => {
      form.value.reset()
    }

    const name = ref('')
    const channels = ref('')

    const submit = async () => {
      if (validate()) {
        const data = {
          name: name.value,
          channels: [...(new Set(channels.value.split('\n').filter(el => el)))],
        }
        emit('submit', data)
      }
    }

    return {
      form,
      name,
      channels,
      validate,
      reset,
      required,
      isNotEmptyString,
      submit,
      icons: {
        mdiCheck,
        mdiAlertCircleOutline,
        mdiCalendar,
      },
    }
  },
}
</script>
