<template>
  <v-form ref="form">
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="name">Text</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-text-field
          id="name"
          v-model="name"
          type="textarea"
          outlined
          dense
          hide-details="auto"
          placeholder="Enter name"
          :rules="[required]"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="channels">Channels</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-textarea
          id="channels"
          v-model="channels"
          hide-details="auto"
          placeholder="Enter channels"
          :rules="[isNotEmptyString]"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { required, isNotEmptyString } from '@core/utils/validation'
import { mdiAlertCircleOutline, mdiCalendar, mdiCheck } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  props: {
    group: {
      type: Object,
      default: () => {},
    },
  },

  setup({ group }) {
    const form = ref(null)

    const validate = () => form.value.validate()
    const reset = () => form.value.reset()

    const name = ref(group.name)
    const channels = ref(group.channels.join('\n'))

    const submit = () => ({
      id: group.id,
      name: name.value,
      channels: [...(new Set(channels.value.split('\n').filter(el => el)))],
    })

    return {
      form,
      name,
      channels,
      validate,
      reset,
      submit,
      required,
      isNotEmptyString,
      icons: {
        mdiCheck,
        mdiAlertCircleOutline,
        mdiCalendar,
      },
    }
  },
}
</script>
