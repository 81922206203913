<template>
  <v-card>
    <v-card-title>
      <v-btn
        v-show="selectedGroups.length"
        class="mr-6"
        icon
        color="error"
        @click="tryDeleteManyGroups(selectedGroups)"
      >
        <v-icon>{{ icons.mdiDeleteForever }}</v-icon>
      </v-btn>
      <TableSearch
        v-model="tableOptions.search"

        hide-filter
      />
      <v-spacer />
      <AddNewGroup
        @submitted="tryFetchGroups"
      />
    </v-card-title>
    <v-card-text>
      <v-data-table
        v-model="selectedGroups"
        hide-default-footer
        :mobile-breakpoint="0"
        item-key="id"
        loading-text="Loading groups ..."
        :server-items-length="total"
        :loading="loading"
        :items="groups"
        :headers="groupHeaders"
        :options.sync="tableOptions"
        show-expand
        :footer-props="{
          'items-per-page-options': pages,
          'show-first-last-page': true,
          'show-current-page': true,
        }"
      >
        <!-- SELECT ROW-->
        <template v-slot:header.data-table-select="{ props, on }">
          <CustomSelectRows
            :value="props"
            :page="tableOptions.page"
            :total="total"
            v-on="on"
          />
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td colspan="1"></td>
          <td
            :colspan="headers.length - 1"
          >
            <v-row class="my-1">
              <v-col
                v-for="(v, index) in item.channels"
                :key="index"
                cols="6"
                md="4"
              >
                {{ v }}
              </v-col>
            </v-row>
          </td>
        </template>
        <template v-slot:item.value="{ item }">
          <v-skeleton-loader
            :loading="item.loading"
            type="table-cell"
          >
            {{ item.value }}
          </v-skeleton-loader>
        </template>
        <template v-slot:item.status="{ item }">
          <v-skeleton-loader
            :loading="item.loading"
            type="table-cell"
          >
            <v-chip
              :color="statusColorMap[item.status] && statusColorMap[item.status].color"
            >
              {{ statusColorMap[item.status] && statusColorMap[item.status].label }}
            </v-chip>
          </v-skeleton-loader>
        </template>
        <template v-slot:item.action="{ item }">
          <TableActions
            :loading="item.loading"
            @edit="openGroupEditModal(item)"
            @delete="tryDeleteGroup(item.id)"
          />
        </template>
        <template v-slot:footer="{ props, on }">
          <TableFooter
            v-bind="props"
            v-on="on"
          />
        </template>
      </v-data-table>
    </v-card-text>

    <GroupEditModal
      ref="editGroupModal"
      @update="tryUpdateGroup"
    />

    <ConfirmDialog
      ref="deleteGroupConfirm"
      max-width="400"
    >
      <template v-slot="{agree, cancel}">
        <v-card>
          <v-card-title>Delete group</v-card-title>
          <v-card-text>
            Do you really want to delete group
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="error"
              @click="agree"
            >
              <v-icon>{{ icons.mdiDeleteForever }}</v-icon>
              Delete
            </v-btn>
            <v-btn
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-card>
</template>

<script>
import {
  mdiDeleteForever,
  mdiDotsVertical,
  mdiPencil,
  mdiMagnify,
} from '@mdi/js'
import pick from 'lodash/pick'
import { mapActions, mapMutations, mapState } from 'vuex'
import store from '@/store'
import telegramStoreModule, { TELEGRAM_MODULE_NAME } from '@/store/modules/telegramStoreModule'

import CustomSelectRows from '@/components/CustomSelectRows.vue'
import AddNewGroup from '@/components/telegram/group/AddNew.vue'
import GroupEditModal from '@/components/telegram/group/EditModal.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import TableActions from '@/components/TableActions.vue'
import TableFooter from '@/components/TableFooter.vue'
import TableSearch from '@/components/TableSearch.vue'

export default {
  components: {
    CustomSelectRows,
    AddNewGroup,
    GroupEditModal,
    ConfirmDialog,
    TableActions,
    TableFooter,
    TableSearch,
  },
  data() {
    return {
      loading: true,
      total: 50,
      tableOptions: {
        search: this.$route.query.search || '',
        sortBy: this.$route.query.sortBy ? [this.$route.query.sortBy] : [],
        sortDesc: +this.$route.query.sortDesc ? [Boolean(+this.$route.query.sortDesc)] : [],
        page: +this.$route.query.page ? +this.$route.query.page : 1,
        itemsPerPage: +this.$route.query.limit ? +this.$route.query.limit : localStorage.getItem('itemsPerPage') || 50,
      },
      search: '',
      selectedGroups: [],
      pages: [50, 100, 200],
      icons: {
        mdiDotsVertical,
        mdiDeleteForever,
        mdiPencil,
        mdiMagnify,
      },
      groupHeaders: [
        {
          text: 'Name',
          value: 'name',
          sortable: true,
          filterable: true,
          align: 'start',
        },
        {
          align: 'end',
          value: 'action',
          sortable: false,
        },
      ],
    }
  },

  computed: {
    ...mapState({
      groups(state) {
        return state[TELEGRAM_MODULE_NAME]?.group.groups
      },
    }),
    mappedOptions() {
      return this.$route.query
    },
  },

  watch: {
    total() {
      this.tableOptions.page = 1
    },
    tableOptions: {
      handler(value) {
        const query = pick(value, ['sortBy', 'page', 'search'])

        query.limit = value.itemsPerPage

        if (value.sortBy?.length) {
          [query.sortBy] = value.sortBy
        }

        if (value.sortDesc?.length) {
          [query.sortDesc] = value.sortDesc
        }

        Object.keys(query).forEach(key => {
          if (!query[key] && key !== 'sortDesc') {
            delete query[key]
          }
        })

        this.tryFetchGroups(query)
        this.$router.replace({ query }).catch(() => {})
      },
      deep: true,
    },
  },

  mounted() {
    // Register module
    if (!store.hasModule(TELEGRAM_MODULE_NAME)) {
      store.registerModule(TELEGRAM_MODULE_NAME, telegramStoreModule)
    }
  },

  methods: {
    ...mapActions({
      fetchGroups(dispatch, payload) {
        return dispatch(`${TELEGRAM_MODULE_NAME}/fetchGroups`, payload)
      },
      updateGroup(dispatch, payload) {
        return dispatch(`${TELEGRAM_MODULE_NAME}/updateGroup`, payload)
      },
      removeGroup(dispatch, payload) {
        return dispatch(`${TELEGRAM_MODULE_NAME}/removeGroup`, payload)
      },
    }),

    ...mapMutations({
      commitSetGroups(commit, payload) {
        return commit(`${TELEGRAM_MODULE_NAME}/SET_GROUPS `, payload)
      },
      commitEditGroup(commit, payload) {
        return commit(`${TELEGRAM_MODULE_NAME}/EDIT_GROUP`, payload)
      },
      commitDeleteGroup(commit, payload) {
        return commit(`${TELEGRAM_MODULE_NAME}/REMOVE_GROUP`, payload)
      },
    }),

    updateList() {

    },

    async tryFetchGroups(params = this.mappedOptions) {
      this.loading = true
      const { data } = await this.fetchGroups(params)
      this.total = data.total
      this.loading = false
    },

    async tryDeleteGroup(id) {
      this.$refs.deleteGroupConfirm.open().then(() => {
        this.commitEditGroup({ id, loading: true })
        this.removeGroup({ id }).then(() => {
          this.commitDeleteGroup({ id })
          this.$message.success('Group deleted successfully')
        }).catch(err => this.$message.error(err))
      })
    },

    async tryUpdateGroup(value) {
      this.commitEditGroup({ ...value, loading: true })
      const { data } = await this.updateGroup({ group: value })
      this.commitEditGroup(data)
    },

    openGroupEditModal(group) {
      this.$refs.editGroupModal.open(group)
    },
  },
}
</script>
